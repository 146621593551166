/* eslint-disable no-param-reassign */

import Axios from 'axios'
import { ACESS_TOKEN_KEY, USER_TOKEN_KEY } from 'config/constants'

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.json()
}

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  if ([403, 401].includes(response.status)) {
    const errorResponse = await response.json()
    if (errorResponse.authUrl) {
      window.location.href = errorResponse.authUrl
    } else if (response.status === 401) {
      window.location.reload()
      localStorage.removeItem(USER_TOKEN_KEY)
      localStorage.removeItem('user')
      localStorage.removeItem('refreshToken')
    }
  }

  const error = new Error(response.statusText)
  error.response = await response.json()
  throw error
}

export default async function request(url, options, retryLimit = 3) {
  const token = window.localStorage.getItem(ACESS_TOKEN_KEY) || ''
  if (!options.headers) {
    options.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const tokenInStorage = window.localStorage.getItem(USER_TOKEN_KEY)

  if (tokenInStorage) {
    options.headers['Authorization'] = `Bearer ${tokenInStorage}`
  }

  options.headers['x-access-token'] = token
  options.headers['current-pathname'] = window.location.pathname

  for (let attempt = 0; attempt < retryLimit; attempt++) {
    try {
      const fetchResponse = await fetch(url, options)
      const response = await checkStatus(fetchResponse)
      return parseJSON(response)
    } catch (error) {
      if (attempt === retryLimit - 1) {
        window.localStorage.removeItem(USER_TOKEN_KEY)
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('refreshToken')
        throw error
      }
    }
  }
}

const axiosInstance = Axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem(ACESS_TOKEN_KEY) || ''
    const tokenInStorage = window.localStorage.getItem(USER_TOKEN_KEY)

    if (!config.headers) {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }

    if (tokenInStorage) {
      config.headers['Authorization'] = `Bearer ${tokenInStorage}`
    }

    config.headers['x-access-token'] = token
    config.headers['current-pathname'] = window.location.pathname

    return config
  },
  (error) => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error

    if (response && response.status === 401) {
      localStorage.removeItem(USER_TOKEN_KEY)
      localStorage.removeItem('user')
      localStorage.removeItem('refreshToken')
      window.location.reload()
      return Promise.reject(error)
    }

    config.retryCount = config.retryCount || 0

    if (config.retryCount < 3) {
      config.retryCount += 1
      return axiosInstance(config)
    } else {
      window.localStorage.removeItem(USER_TOKEN_KEY)
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('refreshToken')
      return Promise.reject(error)
    }
  },
)

export { axiosInstance as axios }
