import { BgColorsOutlined } from '@ant-design/icons'
import { Button, Input, Alert, Result, Tooltip, Spin, Form } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import { createStructuredSelector } from 'reselect'

import { storeQueryParams } from 'containers/App/actions'
import { useDispatch, useSelector } from 'react-redux'

import request from 'utils/request'
import WidgetSelectColor from './selectColor'
import { SUBMISSION_STATUSES } from 'config/constants'
import Adjustment from '../../../icons/Adjustment'
import AdjustmentSignature from './AdjustmentSignature'
import { useCurrentUserData } from 'app/containers/App/hooks'
import { useGetSubmissionByIdQuery } from 'app/containers/App/api'

const WidgetForm = (props) => {
  const { userData } = useCurrentUserData('')

  const dispatch = useDispatch()
  const { match } = props

  const { isFetching, data: submission } = useGetSubmissionByIdQuery(
    match.params.submissionId,
  )
  const signature = useRef()
  const fromSign = useRef()
  const adjustmentSignature = useRef()

  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(null)
  const [success, setSuccess] = useState({
    isSuccess: false,
    action: null,
  })
  const [visibleColor, setvisibleColor] = useState(false)
  const [penColor, setpenColor] = useState('black')
  const [size, setSize] = useState(350)
  const [haveSign, setHaveSign] = useState(false)

  const [formValues, setformValues] = useState({})
  console.log('submission', submission)
  useEffect(() => {
    dispatch(
      storeQueryParams({
        submissionId: match.params.submissionId,
        step: match.params.step,
        respondentEmail: match.params.respondentEmail,
        recipientEmail: match.params.recipientEmail,
        canEditResponse: match.params.canEditResponse,
      }),
    )
  }, [
    dispatch,
    match.params.canEditResponse,
    match.params.recipientEmail,
    match.params.respondentEmail,
    match.params.step,
    match.params.submissionId,
  ])

  const onFinish = async ({ action }) => {
    try {
      setloading(true)
      seterror(null)

      const imgSignature = signature.current
        .getTrimmedCanvas()
        .toDataURL('image/png')
      const data = {
        signature: imgSignature,
        submissionId: match.params.submissionId,
        step: match.params.step,
        respondentEmail: match.params.respondentEmail,
        recipientEmail: match.params.recipientEmail,
        action,
        size,
        ...formValues,
      }

      const response = await request(
        `${import.meta.env.VITE_API_URL}/approval/sign`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      )

      if (!response.success) throw response
      setSuccess({
        isSuccess: true,
        action,
      })
      // success
    } catch (err) {
      if (err.response && err.response.error) {
        seterror(err.response.error)
      } else {
        seterror(`Something went wrong! Please come back later.`)
      }
      if (props.onError) props.onError()
    } finally {
      setloading(false)
    }
  }

  const clear = () => {
    signature.current.clear()
    setHaveSign(false)
  }

  if (success.isSuccess && success.action === SUBMISSION_STATUSES.APPROVED) {
    return (
      <Result
        status="success"
        title="Successfully Approved Request"
        subTitle={`You approved the request ${
          match.params.respondentEmail !== 'null'
            ? `from ${match.params.respondentEmail}`
            : ''
        }. Thank you!`}
      />
    )
  }

  if (success.isSuccess && success.action === SUBMISSION_STATUSES.REJECTED) {
    return (
      <Result
        status="success"
        title="Successfully Rejected Request"
        subTitle={`You rejected the request ${
          match.params.respondentEmail !== 'null'
            ? `from ${match.params.respondentEmail}`
            : ''
        }. Thank you!`}
      />
    )
  }

  return (
    <Spin spinning={loading}>
      <WidgetSelectColor
        visible={visibleColor}
        cancel={() => setvisibleColor(false)}
        setColor={(e) => setpenColor(e)}
        defaultColor={penColor}
      />
      <AdjustmentSignature
        onChange={(sigSize) => {
          setSize(sigSize)
        }}
        ref={adjustmentSignature}
      />

      <Form layout="vertical" ref={fromSign}>
        <h3>Request</h3>

        {error && (
          <div className="signature-alert">
            <Alert type="error" message={error} />
          </div>
        )}

        <Form.Item
          label={
            <div className="sign-label">
              <span>Provide your signature</span>
              <Button onClick={clear}>Reset</Button>
            </div>
          }
        >
          <div className="sign-signature">
            <SignatureCanvas
              penColor={penColor}
              onEnd={() => {
                setHaveSign(true)
              }}
              canvasProps={{ className: 'sigCanvas' }}
              ref={signature}
            />
            <div className="signature-ext">
              <Tooltip title="Adjust signature" placement="right">
                <Button
                  onClick={() => {
                    adjustmentSignature.current.open(
                      signature.current
                        .getTrimmedCanvas()
                        .toDataURL('image/png'),
                    )
                  }}
                  className="signature-action"
                  shape="circle"
                >
                  <Adjustment />
                </Button>
              </Tooltip>
              <Tooltip title="Change signature color" placement="right">
                <Button
                  className="signature-action"
                  shape="circle"
                  onClick={() => setvisibleColor(true)}
                  style={{ color: penColor }}
                >
                  <BgColorsOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
        </Form.Item>
        <Form.Item
          label="Your comment"
          name="comment"
          extra={<div style={{ textAlign: 'right' }}>500 characters</div>}
        >
          <Input.TextArea
            rows={4}
            placeholder="Comment..."
            onChange={(e) => setformValues({ comment: e.target.value })}
            maxLength={500}
          />
        </Form.Item>
        <Form.Item className="signature-button">
          <Button
            htmlType="button"
            type="primary"
            disabled={loading || !haveSign}
            onClick={() => {
              onFinish({ action: SUBMISSION_STATUSES.APPROVED })
            }}
          >
            Approve
          </Button>
          <Button
            htmlType="button"
            type="text"
            disabled={loading || !haveSign}
            onClick={() => {
              onFinish({ action: SUBMISSION_STATUSES.REJECTED })
            }}
          >
            Reject
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default WidgetForm
