/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import { produce } from 'immer'
import { PLANS, QUOTAS, REFRESH_TOKEN } from 'config/constants'

import {
  STORE_QUERY_PARAMS,
  REQUEST_PENDING,
  REQUEST_FINISHED,
  SET_USER,
  SIGN_IN_WITH_GOOGLE,
  SIGN_IN_WITH_GOOGLE_SUCCESS,
  SIGN_IN_WITH_GOOGLE_ERROR,
  GET_CURRENT_USER_DATA,
  GET_CURRENT_USER_DATA_SUCCESS,
  GET_CURRENT_USER_DATA_ERROR,
  SET_REFRESH_TOKEN,
} from './constants'
import {
  ADD_MEMBER_SUCCESS,
  REMOVE_MEMBER_SUCCESS,
} from '../SubscriptionPage/constants'

const refreshToken = localStorage.getItem(REFRESH_TOKEN)
// The initial state of the App
export const initialState = {
  loading: false,
  success: null,
  error: null,
  userData: {},
  currentUser: null,
  refreshToken,
}

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case STORE_QUERY_PARAMS:
        draft.userData = action.payload
        break

      case REQUEST_PENDING:
        draft.loading = true
        break

      case REQUEST_FINISHED:
        draft.loading = false
        break
      case SET_USER:
        draft.currentUser = action.payload.user
        break
      case SIGN_IN_WITH_GOOGLE:
        draft.success = null
        draft.error = null
        break

      case SIGN_IN_WITH_GOOGLE_ERROR:
        draft.success = false
        draft.error = action.payload.error
        break

      case GET_CURRENT_USER_DATA:
        draft.success = null
        draft.error = null
        break

      case SIGN_IN_WITH_GOOGLE_SUCCESS: {
        // const { userData, jwtToken, googleUserInfo } = action.payload.data
        // window.localStorage.setItem(ACESS_TOKEN_KEY, jwtToken)
        // draft.userData = userData
        // if (draft.userData.plan !== PLANS.FREE) {
        //   draft.plan = draft.userData.plan
        // }
        // if (
        //   userData &&
        //   userData.paidQuota &&
        //   userData.paidQuota < QUOTAS.PAID1
        // ) {
        //   draft.paidQuota = QUOTAS.PAID1
        // } else {
        //   draft.paidQuota = userData.paidQuota
        // }

        // draft.currentUser = googleUserInfo

        draft.error = null
        break
      }
      case GET_CURRENT_USER_DATA_SUCCESS:
        draft.userData = action.payload.data
        if ([PLANS.INDIVIDUAL, PLANS.TEAM].includes(draft.userData.plan)) {
          draft.plan = draft.userData.plan
        }
        if (
          action.payload.data &&
          action.payload.data.paidQuota &&
          action.payload.data.paidQuota < QUOTAS.PAID1
        ) {
          draft.paidQuota = QUOTAS.PAID1
        } else {
          draft.paidQuota = action.payload.data.paidQuota
        }
        draft.error = null
        break

      case GET_CURRENT_USER_DATA_ERROR:
        draft.success = false
        draft.error = action.payload.error
        break

      case ADD_MEMBER_SUCCESS:
        draft.userData.teamMembers = draft.userData.teamMembers.concat({
          email: action.payload.teamMember,
          usedQuota: 0,
        })
        break

      case REMOVE_MEMBER_SUCCESS:
        draft.userData.teamMembers = draft.userData.teamMembers.filter(
          (member) => member.email !== action.payload.teamMember,
        )
        break

      case SET_REFRESH_TOKEN:
        draft.refreshToken = action.payload.token
        break
    }
  })

export default appReducer
