/**
 * Create the store with dynamic reducers
 */
import { configureStore as toolkitConfigureStore } from '@reduxjs/toolkit'

import createSagaMiddleware from 'redux-saga'
import createReducer from './reducers'
import rootSaga from './containers/App/saga'
import appApi from 'containers/App/api'

// eslint-disable-next-line default-param-last
export default function configureStore(initialState = {}, history) {
  const reduxSagaMonitorOptions = {}

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state

  const store = toolkitConfigureStore({
    reducer: createReducer(),
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(sagaMiddleware, appApi.middleware),
  })

  sagaMiddleware.run(rootSaga)

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {} // Saga registry

  return store
}
