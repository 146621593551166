import { useMutation, useQuery } from '@tanstack/react-query'
import { USER_TOKEN_KEY } from 'app/config/constants'
import { useCurrentUserData } from 'app/containers/App/hooks'
import request from 'app/utils/request'

const fetchGoogleAuthUrl = async (state) => {
  const response = await request(
    `${import.meta.env.VITE_API_URL}/auth/google`,
    {
      method: 'POST',
      body: JSON.stringify({ state }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
  return response.url
}

export const useGetGoogleAuthRedirect = ({ state, isReAuthorize }) => {
  return useQuery({
    queryKey: ['googleAuth', state],
    queryFn: () => fetchGoogleAuthUrl(state),
    enabled: isReAuthorize,
  })
}

export const useRefreshTokenMutation = () => {
  return useMutation({
    mutationFn: async (refreshToken) => {
      const response = await request(
        `${import.meta.env.VITE_API_URL}/auth/refresh_token`,
        {
          method: 'POST',
          body: JSON.stringify({ refreshToken }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      return response
    },
    onSuccess: (response) => {
      const data = response.data
      localStorage.setItem(USER_TOKEN_KEY, data.accessToken)
      localStorage.setItem('refreshToken', data.refreshToken)
    },
  })
}
