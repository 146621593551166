/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const STORE_QUERY_PARAMS = 'performflow-approvals/App/STORE_QUERY_PARAMS'

export const REQUEST_PENDING = 'performflow-approvals/App/REQUEST_PENDING'
export const REQUEST_FINISHED = 'performflow-approvals/App/REQUEST_FINISHED'

export const SET_USER = 'performflow-app/App/SET_USER'

export const SIGN_IN_WITH_GOOGLE = 'performflow-app/App/SIGN_IN_WITH_GOOGLE'
export const SIGN_IN_WITH_GOOGLE_SUCCESS =
  'performflow-app/App/SIGN_IN_WITH_GOOGLE_SUCCESS'
export const SIGN_IN_WITH_GOOGLE_ERROR =
  'performflow-app/App/SIGN_IN_WITH_GOOGLE_ERROR'

export const GET_CURRENT_USER_DATA = 'performflow-app/App/GET_CURRENT_USER_DATA'
export const GET_CURRENT_USER_DATA_SUCCESS =
  'performflow-app/App/GET_CURRENT_USER_DATA_SUCCESS'
export const GET_CURRENT_USER_DATA_ERROR =
  'performflow-app/App/GET_CURRENT_USER_DATA_ERROR'
export const SIGN_OUT = 'performflow-app/App/SIGN_OUT'
export const SET_REFRESH_TOKEN = 'performflow-app/App/SET_REFRESH_TOKEN'
