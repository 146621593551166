/*
 * SubscriptionConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const SET_USER = 'performflow-app/Subscription/SET_USER'
export const SET_ERROR = 'performflow-app/Subscription/SET_ERROR'

export const SIGN_IN_WITH_GOOGLE =
  'performflow-app/Subscription/SIGN_IN_WITH_GOOGLE'
export const SIGN_IN_WITH_GOOGLE_SUCCESS =
  'performflow-app/Subscription/SIGN_IN_WITH_GOOGLE_SUCCESS'
export const SIGN_IN_WITH_GOOGLE_ERROR =
  'performflow-app/Subscription/SIGN_IN_WITH_GOOGLE_ERROR'

export const GET_CURRENT_USER_DATA =
  'performflow-app/Subscription/GET_CURRENT_USER_DATA'
export const GET_CURRENT_USER_DATA_SUCCESS =
  'performflow-app/Subscription/GET_CURRENT_USER_DATA_SUCCESS'
export const GET_CURRENT_USER_DATA_ERROR =
  'performflow-app/Subscription/GET_CURRENT_USER_DATA_ERROR'

export const ADD_MEMBER = 'performflow-app/Subscription/ADD_MEMBER'
export const ADD_MEMBER_SUCCESS =
  'performflow-app/Subscription/ADD_MEMBER_SUCCESS'
export const ADD_MEMBER_ERROR = 'performflow-app/Subscription/ADD_MEMBER_ERROR'

export const REMOVE_MEMBER = 'performflow-app/Subscription/REMOVE_MEMBER'
export const REMOVE_MEMBER_SUCCESS =
  'performflow-app/Subscription/REMOVE_MEMBER_SUCCESS'
export const REMOVE_MEMBER_ERROR =
  'performflow-app/Subscription/REMOVE_MEMBER_ERROR'

export const GET_REFERRAL_HISTORY =
  'performflow-app/Subscription/GET_REFERRAL_HISTORY'
export const GET_REFERRAL_HISTORY_SUCCESS =
  'performflow-app/Subscription/GET_REFERRAL_HISTORY_SUCCESS'
export const GET_REFERRAL_HISTORY_ERROR =
  'performflow-app/Subscription/GET_REFERRAL_HISTORY_ERROR'

export const SET_INVITED_EMAILS =
  'performflow-app/Subscription/SET_INVITED_EMAILS'

export const SEND_INVITE = 'performflow-app/Subscription/SEND_INVITE'
export const SEND_INVITE_SUCCESS =
  'performflow-app/Subscription/SEND_INVITE_SUCCESS'
export const SEND_INVITE_ERROR =
  'performflow-app/Subscription/SEND_INVITE_ERROR'
