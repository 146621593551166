export const COLLECTIONS = {
  CONFIGS: 'configs',
  RULES: 'rules',
  SUBMISSIONS: 'submissions',
  SUBMISSION_PROGRESS: 'submission_progress',
  RULE_PROGRESS: 'rule_progress',
}

export const PLANS = {
  FREE: 'FREE',
  FREE_TRIAL: 'FREE_TRIAL',
  PAID: 'PAID',
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM',
}

export const PLAN_TERMS = {
  ONE_MONTH: '1',
  THREE_MONTHS: '3',
  SIX_MONTHS: '6',
  TWELVE_MONTHS: '12',
}

export const SUBMISSION_STATUSES = {
  IN_PROGRESS: 'In Progress',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  FEEDBACK_REQUESTED: 'Info Requested',
  COPY_SENT: 'Copy Sent',
  SKIP: 'Skip',
  PENDING: 'Pending',
  CANCELED: 'Canceled',
}

export const SUBMISSION_STATUSES_COLOR = {
  PENDING: {
    backgroundColor: '#fef7e0',
    color: 'orange',
  },
  IN_PROGRESS: {
    backgroundColor: '#fef7e0',
    color: '#ff920f',
  },
  APPROVED: {
    backgroundColor: '#52c41a38',
    color: 'green',
  },
  REJECTED: {
    backgroundColor: '#fff1f0',
    color: 'red',
  },
  FEEDBACK_REQUESTED: {
    backgroundColor: '#d2e3fc',
    color: '#174ea6',
  },
  COPY_SENT: {
    backgroundColor: '#096dd930',
    color: '#68BBE3',
  },
  SKIP: {
    backgroundColor: '#5900ce30',
    color: '#5900ce',
  },
  CANCELED: {
    backgroundColor: '#fff1f0',
    color: 'red',
  },
}

export const RECIPIENT_ROLE_TITLES = {
  APPROVE_REJECT: 'Can approve',
  APPROVE_REJECT_AND_SIGN: 'Can approve & sign',
  RECEIVE_COPY: 'Get notified only',
  RECEIVE_FINAL_EMAIL_ONLY: 'Get final email only',
}

export const APPROVAL_ROLES = {
  APPROVE_REJECT: 'APPROVE_REJECT',
  RECEIVE_COPY: 'RECEIVE_COPY',
  RECEIVE_FINAL_EMAIL_ONLY: 'RECEIVE_FINAL_EMAIL_ONLY',
  APPROVE_REJECT_AND_SIGN: 'APPROVE_REJECT_AND_SIGN',
}

export const GENERATION_ROLE_TITLES = {
  RECEIVE_PDF: 'Receive PDF',
  CAN_VIEW: 'Can view document',
  CAN_EDIT: 'Can edit document',
  CAN_COMMENT: 'Can comment document',
  NONE: 'None',
}

export const MODE_IDS = {
  GENERATE_ONLY: 1,
  GENERATE_AND_SEND: 2,
  APPROVAL_ONLY: 3,
  FULL_MODE: 4,
}

export const QUOTAS = {
  FREE: 100,
  PAID1_1: 1200,
  PAID1: 1200,
  PAID2: 1500,
  PAID3: 2000,
  PAID4: 2500,
  PAID5: 3000,
  PAID6: 4000,
  PAID7: 5000,
  PAID8: 6000,
  PAID9: 7000,
  PAID10: 8000,
  PAID11: 9000,
  PAID12: 10000,
  TEAM1: 5000,
  TEAM2: 10000,
  TEAM3: 15000,
  TEAM4: 20000,
  TEAM5: 25000,
  TEAM6: 50000,
}

export const THREE_MONTH_INDIVIDUAL_QUOTAS = [
  1200, 1500, 2000, 2500, 3000, 4000, 5000, 6000, 7000,
]

export const INDIVIDUAL_QUOTAS = [
  // 800,
  1200, 1500, 2000, 2500, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
]
export const TEAM_QUOTAS = [5000, 10000, 15000, 20000, 25000, 50000]

export const CAN_EDIT_RESPONSE_TRUE = '1'
export const CAN_EDIT_RESPONSE_FALSE = '0'

export const REFERRAL_STATUSES = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
}

export const REFERRAL_STATUS_TEXTS = {
  PENDING: 'Invitation Sent',
  COMPLETED: 'Completed',
}

export const PATHS = {
  SUBSCRIPTION: '/subscription',
  RECIPIENT: '/recipient',
  AUTHORATION: '/authorize',
  MY_FORMS: '/ManageForms',
  NEED_MY_ACTION: '/need-my-approval',
  PAYMENT: '/payment',
}

export const ERROR_MESSAGE = 'Something went wrong, please try again later'

export const WHITE_LIST = ['vuhieu2610@gmail.com', 'tommy@performflow.com']
export const APPROVAL_WHITELIST = ['@fonyou.com']

export const ACESS_TOKEN_KEY = 'access_token'

export const RULE_STATUS = {
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
}

export const COUPON_UNITS = {
  MONEY_DOLLAR: 'MONEY_DOLLAR',
  MONEY_PERCENT: 'MONEY_PERCENT',
  QUOTA_BONUS: 'QUOTA_BONUS',
  MONTHLY_BONUS: 'MONTHLY_BONUS',
  MONTHLY_MINUS: 'MONTHLY_MINUS',
}

export const RESPONSE_THRESHOLD = 3

export const OAUTH_ID_TOKEN = 'oauth_id_token'
export const REFRESH_TOKEN = 'refresh_token'

// use for force user re-login if nescessary
export const USER_TOKEN_KEY = 'token_1'
