/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  STORE_QUERY_PARAMS,
  REQUEST_PENDING,
  REQUEST_FINISHED,
  SET_USER,
  SIGN_IN_WITH_GOOGLE,
  SIGN_IN_WITH_GOOGLE_SUCCESS,
  SIGN_IN_WITH_GOOGLE_ERROR,
  GET_CURRENT_USER_DATA,
  GET_CURRENT_USER_DATA_SUCCESS,
  GET_CURRENT_USER_DATA_ERROR,
  SIGN_OUT,
  SET_REFRESH_TOKEN,
} from './constants'

/**
 * Store querystring parameters into Redux store
 *
 * @param params
 * @returns {{payload: *, type: *}}
 */
export function storeQueryParams(params) {
  return {
    type: STORE_QUERY_PARAMS,
    payload: params,
  }
}

export function startRequest() {
  return {
    type: REQUEST_PENDING,
  }
}

export function finishRequest() {
  return {
    type: REQUEST_FINISHED,
  }
}

export function setUser(user) {
  return {
    type: SET_USER,
    payload: { user },
  }
}

export function signInWithGoogle(code) {
  return {
    type: SIGN_IN_WITH_GOOGLE,
    payload: { code },
  }
}

export function signInWithGoogleSuccess(data) {
  return {
    type: SIGN_IN_WITH_GOOGLE_SUCCESS,
    payload: {
      data,
    },
  }
}

export function signInWithGoogleError(err) {
  return {
    type: SIGN_IN_WITH_GOOGLE_ERROR,
    payload: {
      error: err,
    },
  }
}

export function getCurrentUserData(email, uid, path) {
  return {
    type: GET_CURRENT_USER_DATA,
    payload: {
      id: email,
      uid,
      path,
    },
  }
}
export function getCurrentUserDataSuccess(data) {
  return {
    type: GET_CURRENT_USER_DATA_SUCCESS,
    payload: {
      data,
    },
  }
}

export function getCurrentUserDataError(err) {
  return {
    type: GET_CURRENT_USER_DATA_ERROR,
    payload: {
      error: err,
    },
  }
}

export function signOut() {
  return {
    type: SIGN_OUT,
  }
}

export function setRefreshToken(token) {
  return {
    type: SET_REFRESH_TOKEN,
    payload: {
      token,
    },
  }
}
