/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux'

import history from 'utils/history'
import globalReducer from 'containers/App/reducer'
import appApi from 'containers/App/api'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    global: globalReducer,
    [appApi.reducerPath]: appApi.reducer,
    ...injectedReducers,
  })
}
