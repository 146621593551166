import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import request from 'utils/request'
import { CURRENT_USER_DATA, GOOGLE_USER_INFO } from 'app/constants/queryKeys'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from 'app/config/firebase'
import { useEffect, useMemo, useState } from 'react'
import { PLANS, USER_TOKEN_KEY } from 'app/config/constants'

const useCurrentUserData = () => {
  const queryClient = useQueryClient()
  const [googleUserInfo, setGoogleUserInfo] = useState(
    queryClient.getQueryData(GOOGLE_USER_INFO),
  )
  const userInLocalStorage = localStorage.getItem('user')
  const { email, uid } = googleUserInfo || userInLocalStorage || {}
  const token = localStorage.getItem(USER_TOKEN_KEY)
  const requestUrl = `${import.meta.env.VITE_API_URL}/users/${email}`
  const auth = getAuth()

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [CURRENT_USER_DATA, uid],
    queryFn: async () =>
      await request(requestUrl, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),

    enabled: !!uid && !!email && !!token,
    retry: false,
  })

  const userData = useMemo(
    () =>
      data?.data || {
        email: '',
        plan: PLANS.FREE,
        teamAdmin: '',
        teamMembers: [],
        lastPaymentEndAt: '',
      },
    [data],
  )

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        queryClient.setQueryData(GOOGLE_USER_INFO, user)
        setGoogleUserInfo(user)
      }
    })
  }, [queryClient, auth])

  return { googleUserInfo, userData, isLoading, error, refetch }
}

const useSignOut = () => {
  return useMutation({
    mutationFn: () => {
      signOut(auth)
      const queryClient = useQueryClient()
      queryClient.removeQueries(CURRENT_USER_DATA, { exact: false })
      queryClient.removeQueries(GOOGLE_USER_INFO)
      localStorage.removeItem(USER_TOKEN_KEY)
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('user')
    },
  })
}
export { useCurrentUserData, useSignOut }
