
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, Slider } from 'antd'
import styled from 'styled-components'
import { isFunction } from 'lodash'

const Container = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AdjustmentSignature = ({ onChange }, ref) => {
  const [size, setSize] = useState(350)
  const [image, setImage] = useState(null)

  useImperativeHandle(ref, () => ({
    open: signatureImage => {
      setImage(signatureImage)
    },
  }))

  // useEffect(() => {
  //   if (isFunction(onChange)) onChange(size)
  // }, [onChange, size])

  return (
    (<Modal
      open={!!image}
      onCancel={() => {
        setImage(null)
      }}
      onOk={() => {
        if (isFunction(onChange)) onChange(size)
        setImage(null)
      }}
      centered
      width={720}
    >
      <Container>
        <img
          src={image}
          alt="signature-previewer"
          style={{
            width: size,
            // height: size,
          }}
        />
      </Container>
      <Slider value={size} onChange={setSize} min={100} max={600} />
    </Modal>)
  );
}

export default forwardRef(AdjustmentSignature)
