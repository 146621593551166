import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { auth } from 'config/firebase'
import { Spin } from 'antd'
import { Helmet } from 'react-helmet'
import { isEmpty, isNull, isUndefined, toLower } from 'lodash'
import logo from '../../images/icon-512x512.png'
import WidgetForm from './Widget/form'
import './Styles/main.css'
import { useQueryClient } from '@tanstack/react-query'
import { GOOGLE_USER_INFO } from 'app/constants/queryKeys'
import { useCurrentUserData, useSignOut } from '../App/hooks'

import PrivateComponent from 'app/components/PrivateComponent'
import { USER_TOKEN_KEY } from 'app/config/constants'

const SignPage = ({ match }) => {
  const { userData, isLoading: appLoading, error } = useCurrentUserData('')
  const queryClient = useQueryClient()
  const [googleUserInfo, setGoogleUserInfo] = useState(null)
  const googleUser = queryClient.getQueryData(GOOGLE_USER_INFO)

  useEffect(() => {
    setGoogleUserInfo(googleUser)
  }, [googleUser])

  const signPage = useRef()

  const { forceRecipientSignin: forceRecipientSigninParam } = match.params
  const forceRecipientSignin = useMemo(
    () => forceRecipientSigninParam === '1',
    [forceRecipientSigninParam],
  )

  const recipientEmail = useMemo(
    () => decodeURIComponent(match.params.recipientEmail),
    [match],
  )

  const { mutate: signOut } = useSignOut()
  const doSingOut = useCallback(() => {
    signOut()
    localStorage.removeItem(USER_TOKEN_KEY)
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('user')
    window.location.reload()
  }, [signOut])

  const isReady = useMemo(
    () => !isNull(googleUserInfo) || isUndefined(googleUserInfo),
    [googleUserInfo],
  )

  const isValidAuthRecipient = useMemo(
    () =>
      !forceRecipientSignin ||
      (!isEmpty(googleUserInfo) &&
        toLower(recipientEmail) === toLower(googleUserInfo.email)),
    [recipientEmail, forceRecipientSignin, googleUserInfo],
  )

  const isInValidAuthRecipient = useMemo(
    () =>
      forceRecipientSignin &&
      !isEmpty(googleUserInfo) &&
      toLower(recipientEmail) !== toLower(googleUserInfo.email),
    [recipientEmail, forceRecipientSignin, googleUserInfo],
  )

  const onError = () => {
    signPage.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const renderSignPage = () => {
    return (
      <div id="sign-page" ref={signPage}>
        <Helmet>
          <title>A new request require your sign</title>
        </Helmet>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
          }}
        >
          <img
            src={logo}
            alt="Performflow Logo"
            style={{ width: 40, marginRight: 8 }}
          />
          <h1 style={{ marginBottom: 0 }}>PerformFlow</h1>
        </div>
        <Spin spinning={appLoading} size="large">
          <div className="sign-container">
            <div className="sign-form">
              <WidgetForm match={match} onError={onError} />
            </div>
          </div>
        </Spin>
      </div>
    )
  }

  return forceRecipientSignin && !googleUserInfo && !googleUserInfo?.email ? (
    <PrivateComponent component={renderSignPage} />
  ) : (
    renderSignPage()
  )
}

export default SignPage
