import { all, call, put, takeLatest } from 'redux-saga/effects'
import request from 'utils/request'
import { GoogleAuthProvider, signOut, signInWithRedirect, signInWithPopup } from 'firebase/auth'
import { auth } from 'config/firebase'
import {
  finishRequest,
  getCurrentUserDataError,
  getCurrentUserDataSuccess,
  setUser,
  signInWithGoogleError,
  startRequest,
} from './actions'
import {
  GET_CURRENT_USER_DATA,
  SIGN_IN_WITH_GOOGLE,
  SIGN_OUT,
} from './constants'

function* signInWithGoogleSaga() {
  yield put(startRequest())
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: 'select_account',
  })
  try {
    // Sign in with redirect to prevent popup being blocked
    const data = yield signInWithPopup(auth, provider)
    yield put(setUser(data.user))
  } catch (err) {
    yield put(signInWithGoogleError(err))
  }
  yield put(finishRequest())
}

function* watchSignInWithGoogle() {
  // Watches for SIGN_IN_WITH_GOOGLE actions and calls signInWithGoogleSaga when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(SIGN_IN_WITH_GOOGLE, signInWithGoogleSaga)
}

function* getCurrentUserDataSaga(action) {
  yield put(startRequest())
  const requestUrl = `${import.meta.env.VITE_API_URL}/users/${action.payload.id}?uid=${action.payload.uid}&path=${action.payload.path}`
  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    if (result.success) {
      yield put(getCurrentUserDataSuccess(result.data))
    } else {
      yield put(getCurrentUserDataError(new Error(result.error)))
    }

    yield put(finishRequest())
  } catch (err) {
    yield put(getCurrentUserDataError(err))
    yield put(finishRequest())
  }
}

function* watchGetCurrentUserData() {
  // Watches for GET_CURRENT_USER_DATA actions and calls getCurrentUserDataSaga when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_CURRENT_USER_DATA, getCurrentUserDataSaga)
}

function* signOutSaga() {
  signOut(auth)
}

function* watchSignOut() {
  yield takeLatest(SIGN_OUT, signOutSaga)
}

export default function* rootSaga() {
  yield all([
    watchSignInWithGoogle(),
    watchGetCurrentUserData(),
    watchSignOut(),
  ])
}
