
import { Modal } from 'antd'
import React, { useCallback, useState } from 'react'
import { SketchPicker } from 'react-color'

const WidgetSelectColor = props => {
  const [color, setcolor] = useState(props.defaultColor || '#000')

  const onChange = useCallback(e => {
    setcolor(e.hex)
    if (props.setColor) props.setColor(e.hex)
  }, [])

  return (
    (<Modal
      centered
      onCancel={props.cancel}
      open={props.visible}
      footer={null}
      title={null}
      className="modal-select-color"
    >
      <SketchPicker
        disableAlpha
        color={color}
        onChangeComplete={onChange}
        width="100%"
      />
    </Modal>)
  );
}

export default WidgetSelectColor
