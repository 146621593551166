import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'

const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: (builder) => ({
    getSubmissionById: builder.query({
      query: (id) => ({
        url: `/submission/get-submission-by-submission-id`,
        body: { submissionId: id },
        method: 'POST',
      }),
      transformResponse(baseQueryReturnValue) {
        const { data } = baseQueryReturnValue
        return data
      },
    }),
    getRuleById: builder.query({
      query: (id) => ({
        url: `/rule`,
        body: { ruleId: id },
        method: 'POST',
      }),
      transformResponse(baseQueryReturnValue) {
        const { data } = baseQueryReturnValue
        return data
      },
    }),
    changeApprovalStatus: builder.mutation({
      query: ({ recipient, step, submissionId, status }) => ({
        url: '/recipient/revert-step',
        body: {
          recipient,
          step,
          submissionId,
          status,
          isRevertAll: false,
        },
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetSubmissionByIdQuery,
  useChangeApprovalStatusMutation,
  useGetRuleByIdQuery,
} = appApi

export default appApi
