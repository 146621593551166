// Import all the third party stuff
import React from 'react'
import { ConfigProvider, App as AntdApp } from 'antd'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import history from 'utils/history'
import 'sanitize.css/sanitize.css'
import { GoogleOAuthProvider } from '@react-oauth/google'

// Import root app
import App from 'containers/App'

import configureStore from './configureStore'
import { config } from './config/firebase'

// Create redux store with history
const initialState = {}
const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById('root')

/**
 * Init Google Analytics
 */
ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID)

/**
 * Init Sentry.io - error log management
 * For production only
 */
if (import.meta.env.MODE === 'production') {
  try {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration && Sentry.browserTracingIntegration(),
        Sentry.replayIntegration && Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/apps.performflow.com/,
        new RegExp(import.meta.env.VITE_API_URL),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  } catch (error) {
    console.error('Sentry initialization failed:', error)
  }
}

const root = createRoot(MOUNT_NODE)
// Create a client
const queryClient = new QueryClient()
const CLIENT_ID = config.clientId
root.render(
  <GoogleOAuthProvider clientId={CLIENT_ID} use_fedcm_for_prompt={true}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConfigProvider>
          <AntdApp>
            <App />
          </AntdApp>
        </ConfigProvider>
      </Provider>
    </QueryClientProvider>
  </GoogleOAuthProvider>,
)
