import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import PropsTypes from 'prop-types'

import { useQueryClient } from '@tanstack/react-query'

import { GOOGLE_USER_INFO } from 'app/constants/queryKeys'
import { Spin } from 'antd'
import { useSignOut } from 'app/containers/App/hooks'
import { useGetGoogleAuthRedirect } from './api'
import { USER_TOKEN_KEY } from 'app/config/constants'

function PrivateRoute({ component: Component, ...rest }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isReAuthorize, setIsReAuthorize] = useState(false)
  const queryClient = useQueryClient()
  const auth = getAuth()
  const { mutate: signOut } = useSignOut()

  const tokenInStorage = localStorage.getItem(USER_TOKEN_KEY)
  const userInStorage = (() => {
    try {
      return JSON.parse(localStorage.getItem('user'))
    } catch (e) {
      return null
    }
  })()
  const refreshTokenInStorage = localStorage.getItem('refreshToken')
  const isAuthenticated =
    tokenInStorage?.length > 0 && refreshTokenInStorage?.length > 0

  const { data: url } = useGetGoogleAuthRedirect({
    state: location.pathname,
    isReAuthorize,
  })

  useEffect(() => {
    if (url && isReAuthorize) {
      window.location.href = url
    }
  }, [url, isReAuthorize])

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      setIsLoading(true)

      if (user) {
        queryClient.setQueryData(GOOGLE_USER_INFO, user)
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        if (userInStorage) {
          user = userInStorage
        }
      }

      await handleLogin(user)
      setIsLoading(false)
    })
  }, [])

  const handleSignOut = () => {
    signOut()
    localStorage.removeItem(USER_TOKEN_KEY)
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('user')
    window.location.reload()
  }

  const handleLogin = async (user) => {
    const authResult = queryClient.getQueryData('authResult')
    if (
      (!isLoading && Object.keys(user || userInStorage || {}).length === 0) ||
      !isAuthenticated
    ) {
      const handleGetCurrentUser = async () => {
        if (Object.keys(authResult || {}).length === 0) {
          setIsReAuthorize(true)
        }
      }

      handleGetCurrentUser()
    } else if (!isAuthenticated) {
      signOut()
      queryClient.removeQueries(GOOGLE_USER_INFO)
      localStorage.removeItem(USER_TOKEN_KEY)
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('user')
      window.location.reload()
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <Spin spinning={isLoading}>
          {!isLoading && <Component {...props} singOut={handleSignOut} />}
        </Spin>
      )}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropsTypes.func.isRequired,
}

export default PrivateRoute
